
import { Component, Prop, Vue } from "vue-property-decorator";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

import Rules from "@/app/modules/_global/classes/Rules";

type Upload = {
  data: {
    id: number;
  };
};

@Component
export default class CreatePostPage extends Vue {
  @Prop(Number) readonly postId!: number;

  post = {};
  valid = true;
  postPhotoSource? = "";
  formData = new FormData();

  rules = {
    required: Rules.required
  };

  async takePicture() {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt
    });

    this.postPhotoSource = photo.webPath;

    if (photo.webPath) {
      const blob = await fetch(photo.webPath).then(r => r.blob());
      this.formData.append("file", blob);
    }
  }

  removePicture() {
    this.formData.delete("file");
    this.postPhotoSource = "";
  }

  async store() {
    let upload: Upload;

    if (this.formData.has("file")) {
      upload = await this.$store.dispatch("posts/uploadPicture", this.formData);
      if (upload) this.post["upload_id"] = upload.data.id;
    }

    this.$store.dispatch("posts/store", this.post).then(() => {
      this.$store.commit("posts/setPersonalData", null);
      this.$store.commit("posts/clearData");
      this.$router.back();
    });
  }
}
